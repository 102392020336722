import axios, { Method } from 'axios';
import { refreshIfTokenInvalid } from './AuthRequest';
import { getAccessToken } from '../auth/tokenAccess';
import axiosInstance from './fetchData';
// import { refreshIfTokenInvalid } from "contexts/auth/token_helpers";
// import { getAccessToken } from "contexts/auth/tokenAccess";

type AuthRequestParams = {
  quantity?: number;
};
const X_API_KEY = '8833a01f-551e-4680-b221-f9267eba498c';

export const authRequest = async (
  URL: string,
  httpMethod: Method = 'GET',
  params = {},
) => {
  // await refreshIfTokenInvalid();
  // let config = {
  //   method: httpMethod,
  //   headers: {
  //     Authorization: `Bearer ${getAccessToken()}`,
  //     'x-api-key': X_API_KEY,
  //   },
  // };
  // if (httpMethod === 'GET') {
  //   config['params'] = params;
  // } else {
  //   config['data'] = params;
  // }
  // const response = await axios(URL, config);
  // return response.data;
  try {
    const token = localStorage.getItem('bo_token');
    const response = await axiosInstance({
      token,
      // header: { Prefer: 'count=exact' },
    })({
      ...(httpMethod === 'GET'
        ? { params: params }
        : httpMethod === 'POST' ||
          httpMethod === 'PUT' ||
          httpMethod === 'DELETE'
        ? { data: params }
        : {}),
      url: URL,
      method: httpMethod,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    if (error && error.response) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }
};

export const regularRequest = async (
  URL: string,
  httpMethod: Method = 'GET',
  params = {},
  multipart: boolean = false,
) => {
  let config = {
    method: httpMethod,
  };
  if (httpMethod === 'GET') {
    config['params'] = params;
  } else {
    config['data'] = params;
  }
  if (multipart) {
    let newConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': X_API_KEY,
      },
    };
    const response = await axios.post(URL, params, newConfig);
    return response.data;
  }
  const response = await axios(URL, config);
  return response.data;
};
