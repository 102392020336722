import { authRequest } from './makeRequest';
import {
  ProductsPaginatedResponse,
  UploadImageResponse,
  CreateProductRequest,
  EditProductRequest,
} from '../customTypes/CustomTypes';
import HOST from './APIHost';

export const getProductsPaginatedAPI = async ({
  key,
  next_page = 0,
  search_text = '',
  department = '',
  category = '',
  offset = 0,
}: {
  key?: any;
  next_page: number;
  offset: number;
  search_text: string;
  department: string;
  category: string;
}): Promise<ProductsPaginatedResponse> => {
  // Add Department & URLs
  let url = `shoppa/product`;
  try {
    let res = await authRequest(url, 'GET', {
      limit_var: 20,
      offset_var: next_page * 20,
      ...(search_text ? { p_search: search_text } : {}),
      ...(department && category
        ? { department_id: department, category_id: category }
        : department
        ? { department_id: department }
        : category
        ? { category_id: category }
        : {}),
    });
    // Add name: Remove this hack later by replacing name with title in the frontend.
    res.next_page = next_page + 1;
    res.currentCount = (offset + 1) * 20;
    res.offset = (offset + 1) * 20;
    res.products = (res.products || []).map((item) => {
      return {
        ...item,
        name: item.title,
        next_page: next_page + 1,
      };
    });
    return res;
  } catch (e) {
    return {
      error: `Error Fetching Products: ${e}`,
      products: [],
      count: 0,
      currentCount: 0,
      offset: 0,
    };
  }
};

export const getAllProductsAPI = async () => {
  const url = `products/`;
  // Make Request
  try {
    return await authRequest(url);
    // Add name: Remove this hack later by replacing name with title in the frontend.
  } catch (e) {
    console.log('Error fetching all products', e);
  }
};

export const getAllProductStatusAPI = async () => {
  const url = `shoppa/bo/dropdown`;
  // Make Request
  try {
    return await authRequest(url, 'GET');
    // Add name: Remove this hack later by replacing name with title in the frontend.
  } catch (e) {
    console.log('Error fetching all products', e);
  }
};

export const createProductAPI = async (product_data: CreateProductRequest) => {
  // Add Department & URLs
  let url = 'shoppa/product';

  // Make Request
  try {
    return await authRequest(url, 'POST', product_data);
    // Add name: Remove this hack later by replacing name with title in the frontend.
  } catch (e) {
    console.log('Error creating  product', e);
  }
};

export const editProductAPI = async (product_data: EditProductRequest) => {
  // Add Department & URLs
  let url = 'shoppa/product';

  // Make Request
  try {
    return await authRequest(url, 'PUT', product_data);
    // Add name: Remove this hack later by replacing name with title in the frontend.
  } catch (e) {
    console.log('Error creating  product', e);
  }
};
export const deleteProductAPI = async (p_product_id: {
  p_product_id: string;
}) => {
  // Add Department & URLs
  let url = `${HOST}shoppa/product`;

  // Make Request
  try {
    const result = await authRequest(url, 'DELETE', p_product_id);
    return result;

    // Add name: Remove this hack later by replacing name with title in the frontend.
  } catch (e) {
    console.log('Error deleting product', e);
  }
};
