import React, { createContext, useState } from 'react';
import { getProductsPaginatedAPI } from '../APICalls/ProductsRequest';
import { getAllDepartmentsAPI } from '../APICalls/DepartmentsRequest';
import { getCategoriesAPI } from '../APICalls/CategoriesRequest';

import {
  DepartmentsResponse,
  CategoriesResponse,
  ProductsPaginatedResponse,
  ProductDepartmentsType,
} from '../customTypes/CustomTypes';
import { number } from 'yup';

type InitialTokenStateType = {
  items: string;
  // refresh_token: string;
};

const INITIAL_CATEGORY = {
  id: '',
  title: 'Select category',
  slug: '',
  type: '',
  type_id: '',
  type_slug: '',
  children: [],
  products: '',
  icon: '',
  category_id: '',
  category_name: '',
  category_product_count: 0,
  category_slug: '',
};

const INITIAL_DEPARTMENT: ProductDepartmentsType = {
  slug: '',
  name: 'Department',
  id: '',
  categories: [
    { category_id: '', category_slug: '', category_name: 'Select category' },
  ],
  icon: '',
};

const initialState = {
  listCount: 0,
  currentCount: 0,
  error: null,
  isFetching: false,
  isFetchingMore: null,
  products: [],
  fetchMore: async ({
    next_page = 0,
    search = '',
    department = '',
    category = '',
  }: {
    next_page: number;
    search: string;
    department: string;
    category: string;
  }) => null,
  nextPage: 1,
  setSearch: (string: string) => null,
  search: '',
  refetch: ({
    next_page = 0,
    search = '',
    department = '',
    category = '',
  }: {
    next_page: number;
    search: string;
    department: string;
    category: string;
  }) => null,
  department: INITIAL_DEPARTMENT,
  setDepartment: (department: ProductDepartmentsType) => null,
  category: INITIAL_CATEGORY,
  setCategory: (category: CategoriesResponse) => null,
  handleSearchSubmit: (search: string) => null,
  // categories: [],
  departments: [],
  // price: [],
  // salesprice: [],
  handleSetDepartment: (event: any) => null,
  handleSetCategory: (event: any) => null,
  toggleCategoryRedirect: false,
  setToggleCategoryRedirect: (bool: boolean) => null,
  category_slug_from_category: '',
  department_slug_from_category: '',
  setDepartment_slug_from_category: (string: string) => null,
  setCategory_slug_from_category: (string: string) => null,
  INITIAL_DEPARTMENT,
  INITIAL_CATEGORY,
};

const INITIAL_STATE = {
  next_page: 1,
  products: [],
  count: 0,
  offset: 0,
  currentCount: 0,
};

export const ProductsContext = createContext(initialState);

export const ProductsProvider = ({ children }) => {
  // const [tokenState, tokenDispatch] = useReducer(tokenReducer, initialState);
  const [search, setSearch] = React.useState('');
  const [department, setDepartment] = React.useState<ProductDepartmentsType>(
    INITIAL_DEPARTMENT,
  );
  const [category, setCategory] = React.useState<CategoriesResponse>(
    INITIAL_CATEGORY,
  );
  const [departments, setDepartments] = React.useState([]);
  // const [categories, setCategories] = React.useState<CategoriesResponse[]>([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [toggleCategoryRedirect, setToggleCategoryRedirect] = React.useState(
    false,
  );
  const [
    category_slug_from_category,
    setCategory_slug_from_category,
  ] = useState('');
  const [
    department_slug_from_category,
    setDepartment_slug_from_category,
  ] = useState('');
  const [reload, setReload] = React.useState(false);
  const [reroute, setReroute] = React.useState(false);
  const [isFetchingMore, setIsFetchingMore] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [next_page, setNextPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [currentCount, setCurrentCount] = React.useState(0);
  const [
    { products, error },
    setAllProducts,
  ] = React.useState<ProductsPaginatedResponse>(INITIAL_STATE);
  // console.log('category :>> ', category);
  // console.log('department :>> ', department);
  const FETCH_PRODUCTS = async ({
    next_page = 0,
    search = '',
    department = '',
    category = '',
  }: {
    next_page: number;
    search: string;
    department: string;
    category: string;
  }) => {
    setIsFetching(true);
    const data = await getProductsPaginatedAPI({
      next_page,
      search_text: search,
      category,
      department,
      offset,
    });
    setIsFetching(false);
    setAllProducts(data);
    setNextPage(data.next_page);
    setTotalCount(data.count);
    setCurrentCount(data.currentCount);
    setOffset(data.offset);
    return null;
  };

  const FETCH_DEPARTMENTS = async () => {
    setIsFetching(true);
    const data = await getAllDepartmentsAPI();
    setIsFetching(false);
    setDepartments(data);
    return null;
  };

  const fetchMore = async ({
    next_page = 0,
    search = '',
    department = '',
    category = '',
  }: {
    next_page: number;
    search: string;
    department: string;
    category: string;
  }) => {
    setIsFetchingMore(true);
    const data = await getProductsPaginatedAPI({
      next_page,
      search_text: search,
      category,
      department,
      offset: next_page,
    });
    setIsFetchingMore(false);
    setAllProducts({ ...data, products: products.concat(data.products) });
    setNextPage(data.next_page);
    setTotalCount(data.count);
    setCurrentCount(data.currentCount);
    setOffset(data.offset);

    return null;
  };

  const refetchProductsOnCategoryChange = async () =>
    FETCH_PRODUCTS({
      next_page: 0,
      search,
      department: department.id,
      category: category.id,
    });

  const handleReroute = () => {
    setReroute(true);
  };
  const handleSetDepartment = async (event) => {
    setNextPage(0);
    setTotalCount(0);
    setCurrentCount(0);
    setOffset(0);
    setCategory(INITIAL_CATEGORY); // Reset Category
    setDepartment(event.value[0]);
    // return await FETCH_PRODUCTS({
    //   next_page: 0,
    //   search,
    //   department: event.value[0].department_id,
    //   category: '',
    // });
  };
  const handleSetCategory = async (event) => {
    const { option, value } = event;
    setNextPage(0);
    setTotalCount(0);
    setCurrentCount(0);
    setOffset(0);
    setCategory(option?.category || value[0]);
    return await FETCH_PRODUCTS({
      next_page: 0,
      search,
      department: department.id,
      category: event.value[0].category_id,
    });
  };

  const handleSearchSubmit = async (search) => {
    setNextPage(0);
    setTotalCount(0);
    setCurrentCount(0);
    setOffset(0);
    setSearch(search);
    setReload(!reload);
    // refetch({ searchText: value });
  };

  React.useEffect(() => {
    FETCH_PRODUCTS({
      next_page,
      search,
      department: department.id,
      category: category.id,
    });
  }, [search, department]);
  React.useEffect(() => {
    if (category_slug_from_category && department_slug_from_category) {
      FETCH_PRODUCTS({
        next_page: 0,
        search,
        department: department_slug_from_category,
        category: category_slug_from_category,
      });
    }
  }, [toggleCategoryRedirect]);

  React.useEffect(() => {
    FETCH_DEPARTMENTS();
  }, []);

  // React.useEffect(() => {
  //   FETCH_CATEGORIES(department.slug);
  // }, [department]);

  // if (data) setAllProducts(data.products);

  return (
    <ProductsContext.Provider
      value={{
        error: error,
        products: products,
        isFetching: isFetching,
        isFetchingMore: isFetchingMore,
        fetchMore: fetchMore,
        nextPage: next_page,
        setSearch: setSearch,
        search: search,
        refetch: FETCH_PRODUCTS,
        department: department,
        setDepartment: setDepartment,
        setCategory: setCategory,
        category: category,
        listCount: totalCount,
        currentCount,
        // categories: categories,
        departments: departments,
        handleSearchSubmit: handleSearchSubmit,
        handleSetCategory: handleSetCategory,
        handleSetDepartment: handleSetDepartment,
        toggleCategoryRedirect: toggleCategoryRedirect,
        setToggleCategoryRedirect: setToggleCategoryRedirect,
        category_slug_from_category,
        department_slug_from_category,
        setCategory_slug_from_category,
        setDepartment_slug_from_category,
        INITIAL_DEPARTMENT,
        INITIAL_CATEGORY,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
