import axios from 'axios';

const axiosInstance = ({ token = null, header = {}, params = null } = {}) => {
  const axiosData = axios.create({
    baseURL: 'https://api.enterpriseinsight.co:8085/v1/',
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      'Content-Type': 'application/json;charset=UTF-8',
      'x-api-key': '8833a01f-551e-4680-b221-f9267eba498c',
      Accept: 'application/vnd.pgrst.object+json',
      // 'Cache-Control': 'max-age=0,must-revalidate',
      ...header,
    },
    ...(params ? { params: params } : {}),
  });

  axiosData.interceptors.response.use(
    (response) => response,
    // (error) => {
    //   return error.response;
    // },
  );
  // console.log("axiosData :>> ", axiosData);
  return axiosData;
};

export default axiosInstance;
