import { authRequest } from './makeRequest';
import {
  DepartmentsResponse,
  ProductDepartmentsType,
  CreateDepartmentRequest,
  EditDepartmentRequest,
} from '../customTypes/CustomTypes';
import HOST from './APIHost';
import { getAllProductsAPI } from './ProductsRequest';
import { getIsAuthenticated } from '../context/auth';

export const getAllDepartmentsAPI = async (): Promise<
  ProductDepartmentsType[]
> => {
  // Add Department & URLs
  const url = `shoppa/product/department/`;
  // Make Request
  try {
    let { ProductDepartments = [] } = await authRequest(url, 'GET');
    // return addProductsToDepartments(res);
    return ProductDepartments;
    // Add name: Remove this hack later by replacing name with title in the frontend.
  } catch (e) {
    console.log('Error fetching categories', e);
  }
};

export const getDepartmentsAPI = async (): Promise<DepartmentsResponse[]> => {
  // Add Department & URLs
  let url = `${HOST}/departments/`;
  // Make Request
  try {
    let res: DepartmentsResponse[] = await authRequest(url, 'GET');
    // Add name: Remove this hack later by replacing name with title in the frontend.
    return res;
  } catch (e) {
    console.log('Error fetching categories', e);
  }
};

export const createDepartmentAPI = async (name: string) => {
  // Add Department & URLs
  let url = `shoppa/product/department`;

  // Make Request
  try {
    return await authRequest(url, 'POST', { p_department_name: name });
  } catch (e) {
    console.log('Error creating  department', e);
  }
};

export const editDepartmentAPI = async (
  category_data: EditDepartmentRequest,
) => {
  // Add Department & URLs
  let url = `shoppa/product/department`;

  // Make Request
  try {
    return await authRequest(url, 'PUT', category_data);
  } catch (e) {
    console.log('Error editing  department', e);
  }
};

export const deleteDepartmentAPI = async (department_id: string) => {
  // Add Department & URLs
  let url = 'shoppa/product/department';
  // Make Request
  try {
    return await authRequest(url, 'DELETE', { p_department_id: department_id });
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response; // take everything but 'request'
    console.log(errorObject);
    console.log(
      'error deleting department. Here is errorObject ->',
      errorObject,
    );
    console.log('Here is original request ->', request);
    console.log('Here is isAuthenticated', getIsAuthenticated());
    const errorMsg =
      errorObject.data.detail === 'X-Token header invalid'
        ? 'X-Token header invalid. Pleaes log out and log back in.'
        : errorObject;
    return {
      error: errorMsg,
    };
  }
};
